<div class="questions">
    <span class="questions-title non-printable-panel">Questions?</span>
    <a class="non-printable-panel" (click)="openChat()" [ee-google-tag]="isBusinessHours() ? 'Opened chat' : 'Showing unavailable chat dialog'">Chat,</a>
    <a class="non-printable-panel" target="_blank" ee-google-tag="Email link click" href="mailto:cs@emersonecologics.com">Email</a>
    <span class="only-printable-panel">Email cs@emersonecologics.com</span>
    <span class="questions-desktop-link">or Call 800-654-4432</span>
    <span class="questions-mobile-link">or Call <a ee-google-tag="Mobile phone tap" href="tel:+1-800-654-4432">800-654-4432</a></span>
</div>
<div class="hours">
    <div class="non-printable-panel">Call and Chat available</div>
    <div class="only-printable-panel">Call available</div>
    <div>M-F 9:00 AM - 8:00 PM EST</div>
</div>